import React from 'react';
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import ListComponent from '../Components/ListComponent';

const FIELDS = [{
    field: 'service',
    title: 'Service',
    type: 'service',
    form: true,
}, {
    field: 'port',
    title: 'Port',
    type: 'number',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}]

export default class ServerPorts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                {this.props.server && this.props.server.pk && <ListComponent
                    title="Ports"
                    url={`/servers/${this.props.server.pk}/ports/`}
                    fields={FIELDS}
                    filterDelete={port => port.service.code !== 'ssh'}
                />}
            </div>
        );
    }
}
