import React from 'react';
import { useState } from 'react';
import DateTime from './DateTime';
import Http from '../Api/Http';
import { Icon, IconList } from './Icon';
import { DetailButtonComponent } from './DetailComponent';
import Button, { ConfirmButton, RefreshButton, ModalButton } from './Buttons';
import { DockDetailButtonComponent } from '../Servers/Components/DockComponent';
import Paginator from './Paginator';

const TD_MAX_SIZE = 30;

export default function ListComponent(props) {
    const [isUpdating, setUpdating] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [pages, setPages] = useState({});
    const [showButtons, setShowButtons] = useState(true);
    const [objects, setObjects] = useState(updateObjects);

    function updateObjects() {
        if (props.url) {
            setUpdating(true);
            let url = props.url;
            let args = [];
            if (pages && pages.page && pages.page > 1)
                args.push(`page=${pages.page}`);
            if (props.size)
                args.push(`size=${props.size}`);
            if (sortField)
                args.push(`ordering=${sortField}`);
            if (args)
                url += '?' + args.join('&');
            Http.get(url).then(res => {
                setUpdating(false);
                setObjects(res.data.results || []);
                setPages({
                    count: res.data.count,
                    pages: res.data.pages,
                    page: res.data.page,
                });
                setShowButtons(!props.noEdit || !props.noDelete || props.buttons || props.confirmButtons || props.modalButtons);
            });
        }
    }

    function changePage(page) {
        pages.page = page;
        setPages(pages);
        updateObjects();
    }

    function deleteObject(obj) {
        Http.delete(`${props.url}${obj.pk}/`).then(updateObjects);
    }

    function sortBy(field) {
        if (sortField === field) field = '-' + sortField;
        if (sortField !== field) {
            setSortField(field);
            updateObjects();
        }
    }

    function sortedBy(field) {
        if (sortField === field) return 'sortup';
        if (sortField === '-' + field) return 'sortdown';
        return 'sort';
    }

    function sortObjects() {
        if (objects && sortField) {
            var res = objects;
            var field = sortField;
            var dr = 1;
            if (field[0] === '-') {
                field = field.slice(1,);
                dr *= -1;
            }
            res.sort((a, b) => a[field] > b[field] ? dr : dr*-1);
            setObjects(res);
        }
    }

    function renderTD(obj, field) {
        if (!obj || !obj[field.field]) return '-';
        switch (field.type) {
            case 'bool':
            case 'fs':
            case 'source':
                return <Icon icon={IconList[obj[field.field] ? 'yes' : 'no']}/>;
            case 'dock':
                return (
                    <span>
                        {obj[field.field].name} / {obj[field.field].code}&nbsp;
                        <DockDetailButtonComponent pk={obj[field.field].pk}/>
                    </span>
                );
            case 'app':
            case 'service':
                return obj[field.field].name;
            case 'password':
                return <Button
                    title="Copy password"
                    color="light"
                    style="btn-sm"
                    onClick={() => {navigator.clipboard.writeText(obj[field.field])}}
                    text={<Icon icon={IconList.copy}/>}
                />;
            case 'textarea':
                let text = obj[field.field];
                if (text.length > TD_MAX_SIZE) {
                    if (field.textEnd) text = '..' + text.slice(text.length - TD_MAX_SIZE + 2);
                    else text = text.slice(0, TD_MAX_SIZE - 2) + '..';
                }
                return <ModalButton
                    text={text}
                    header={field.title}
                    dialog_styles="modal-lg"
                    style="btn-link"
                    body={<pre className="text-start">{obj[field.field]}</pre>}
                />
            case 'datetime':
                return <DateTime date={obj[field.field]}/>;
            case 'list':
                return <span title={obj[field.field].toString()}>{obj[field.field].length}</span>;
            case 'args':
                return <span>{Object.keys(obj[field.field]).length}</span>;
            case 'custom':
                return field.customRow(obj[field.field], obj);
            default:
                return obj[field.field];
        }
    }

    React.useEffect(updateObjects, [props.refresh]);

    return (
        <div>
            <div className="row">
                <h5 className="col-8 title-h1">
                    {props.title}
                </h5>
                <div className="col">
                    <div className="btn-group float-end">
                        {props.headButtons && props.headButtons.map((btn, index) => {
                            return (
                                <Button key={index}
                                    color={btn.color}
                                    style={btn.style}
                                    onClick={btn.onClick}
                                    title={btn.title || ''}
                                    text={btn.text || <Icon icon={btn.icon}/>}
                                    disabled={btn.disabled}
                                />
                            )
                        })}
                        {!props.noAdd && <DetailButtonComponent
                            title="Add new"
                            text={<Icon icon={IconList.add}/>}
                            color="info"
                            style="btn-lg"
                            url={props.url}
                            inputs={props.fields}
                            onSubmit={updateObjects}
                        />}
                        {!props.noUpdate && <RefreshButton
                            onClick={updateObjects}
                            disabled={isUpdating}
                        />}
                    </div>
                </div>
            </div>
            <br/>
            <table className="table table-striped table-hover text-center align-middle">
                <thead>
                    <tr>
                        <th>#</th>
                        {props.fields.map((field, index) => !field.hide && (
                            <th key={index}>
                                {field.noSort ? field.title : (
                                    <span>
                                        <a role="button" onClick={() => {sortBy(field.field)}}>{field.title}</a>
                                        &nbsp;
                                        <Icon icon={IconList[sortedBy(field.field)]}/>
                                    </span>
                                )}
                            </th>
                        ))}
                        {showButtons && <th className="float-end"><Icon icon={IconList.config}/></th>}
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {objects && objects.map((obj, index) => {
                        return (
                            <tr key={obj.pk}>
                                <td>{index+1}</td>
                                {props.fields.map((field, index) => !field.hide && <td key={index}>{renderTD(obj, field)}</td>)}
                                {showButtons && (
                                    <td>
                                        <div className="btn-group float-end">
                                            {props.buttons && props.buttons.map((btn, index) => {
                                                return (!btn.filter || btn.filter(obj)) && <Button key={index}
                                                    color={btn.styleCalc ? btn.styleCalc(obj) : btn.color}
                                                    style={btn.style}
                                                    onClick={() => {btn.onClick && btn.onClick(obj)}}
                                                    title={btn.title || ''}
                                                    text={btn.text}
                                                    disabled={btn.disabled && btn.disabled(obj)}
                                                />
                                            })}
                                            {props.confirmButtons && props.confirmButtons.map((btn, index) => {
                                                return (!btn.filter || btn.filter(obj)) && <ConfirmButton
                                                    key={index}
                                                    title={btn.title}
                                                    text={btn.text}
                                                    color={btn.color}
                                                    style={btn.style}
                                                    question={btn.question}
                                                    onConfirm={() => btn.onConfirm && btn.onConfirm(obj)}
                                                    disabled={btn.disabled && btn.disabled(obj)}
                                                />
                                            })}
                                            {props.modalButtons && props.modalButtons.map((btn, index) => {
                                                return (!btn.filter || btn.filter(obj)) && <ModalButton
                                                    key={index}
                                                    title={btn.title}
                                                    text={btn.text}
                                                    header={btn.header}
                                                    dialog_styles={btn.dialog_styles}
                                                    color={btn.color}
                                                    style={btn.style}
                                                    body={btn.body}
                                                    bodyCalc={() => btn.bodyCalc && btn.bodyCalc(obj)}
                                                    footer={btn.footer}
                                                    onOpen={() => btn.onOpen && btn.onOpen(obj)}
                                                    onClose={() => btn.onClose && btn.onClose(obj)}
                                                    disabled={btn.disabled && btn.disabled(obj)}
                                                />
                                            })}
                                            {!props.noEdit && (!props.filterEdit || props.filterEdit(obj)) && <DetailButtonComponent
                                                title="Edit"
                                                text={<Icon icon={IconList.edit}/>}
                                                color="light"
                                                url={props.url}
                                                inputs={props.fields}
                                                object={obj}
                                                onSubmit={updateObjects}
                                            />}
                                            {!props.noDelete && (!props.filterDelete || props.filterDelete(obj)) && <ConfirmButton
                                                title="Delete"
                                                text={<Icon icon={IconList.delete}/>}
                                                color="danger"
                                                question={`Are you sure you want to delete "${obj.name || obj.code || 'object'}"?`}
                                                onConfirm={() => deleteObject(obj)}
                                            />}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Paginator
                pages={pages.pages}
                page={pages.page}
                count={pages.count}
                onChange={changePage}
            />
        </div>
    )
}

export function ListButtonComponent(props) {
    const [isOpen, setOpen] = useState(props.default || false);

    const open = () => {
        setOpen(true);
        props.onOpen && props.onOpen();
    }

    const close = () =>  {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        <span>
            {isOpen && <ListComponent
                url={props.url}
                fields={props.fields}
                onSubmit={props.onSubmit}
                onClose={close}
                noAdd={props.noAdd}
                noEdit={props.noEdit}
                noDelete={props.noDelete}
                headButtons={props.headButtons}
                buttons={props.buttons}
            />}
            <Button
                color={props.color}
                style={props.style}
                onClick={open}
                title={props.title}
                text={props.text}
            />
        </span>
    )
}
