import axios from 'axios';


export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: (s) => s >= 200 && s <= 500,
});
