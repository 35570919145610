import React from 'react';
import DateTime from './DateTime';
import { Icon, IconList } from './Icon';
import { formatSize } from './Formats';
import { ConfirmButton, ModalButton } from './Buttons';

export class FileTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDirs: [],
        }
        this.switchDir = this.switchDir.bind(this);
    }

    switchDir(dir) {
        let openDirs = this.state.openDirs;
        if (this.state.openDirs.indexOf(dir) > -1)
            openDirs = openDirs.filter(obj => obj !== dir);
        else
            openDirs.push(dir);
        this.setState({ openDirs: openDirs });
    }

    isDirOpen(dir) {
        return this.state.openDirs.indexOf(dir) > -1;
    }

    render() {
        return (
            <div>
                {this.props.files && this.props.files.map((file, index) => {
                    return (
                        <div key={index} className="row">
                            <div className="col-8 text-start">
                                {[...Array(this.props.level).keys()].map((index) => <span key={index}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                                {file.name}
                                &nbsp;
                                <div className="btn-group btn-sm float-end">
                                    {file.content && <ModalButton
                                        title="File content"
                                        text=<Icon icon={IconList.info}/>
                                        header={file.name}
                                        dialog_styles="modal-xl"
                                        style="none btn-sm"
                                        body={<pre className="text-break">{file.content}</pre>}
                                    />}
                                    {file.children && (
                                        <button
                                            className={'btn btn-sm'}
                                            onClick={() => this.switchDir(file.name)}
                                            disabled={file.children.length < 1}
                                        >
                                            <Icon icon={this.isDirOpen(file.name) ? IconList.minus : IconList.plus}/>
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="col-2">
                                {formatSize(file.size)}
                            </div>
                            <div className="col-1">
                                <DateTime date={file.updated_at}/>
                            </div>
                            <div className="col-1">
                                {this.props.onDelete && <ConfirmButton
                                    title="Delete"
                                    text={<Icon icon={IconList.delete}/>}
                                    style="danger btn-sm"
                                    question={`Are you sure you want to delete "${file.name}"?`}
                                    onConfirm={() => this.props.onDelete(file)}
                                />}
                            </div>
                            {this.isDirOpen(file.name) && <FileTree files={file.children} onDelete={this.props.onDelete} level={this.props.level + 1}/>}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default class FileListComponent extends React.Component {
    render() {
        return (
            <div>
                <div className="row fw-bolder">
                    <div className="col-8 text-start">Filename</div>
                    <div className="col-2">Size</div>
                    <div className="col-1">At</div>
                    <div className="col-1 text-end"><Icon icon={IconList.config}/></div>
                </div>
                <hr/>
                {this.props.files && this.props.files.length ? <FileTree files={this.props.files} onDelete={this.props.onDelete} level={0}/> : <h4>Waiting files...</h4>}
            </div>
        );
    }
}
