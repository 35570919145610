import { useState } from 'react';
import ReactCountryFlag from "react-country-flag"
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import { Usage } from '../Components/Elements';
import { formatSize } from '../Components/Formats';
import List from '../Components/Lists';
import Button, { ConfirmButton, FormButton, RefreshButton } from '../Components/Buttons';
import DateTime from '../Components/DateTime';

export default function ServerUsage(props) {
    const [instance, setInstance] = useState(updateData);
    const [waitInstance, setWaitInstance] = useState(false);

    function updateData() {
        props.onUpdate && props.onUpdate();
        Http.get(`/servers/${props.server.pk}/instance/`).then(res => {
            if (res.data.message) {
                console.log(res.data);
            } else {
                setInstance(res.data);
            }
        });
    }

    function updateInstance() {
        Http.post(`/servers/${props.server.pk}/instance/`).then(res => {
            !res.data.message && setInstance(res.data);
            console.log('Updated', res.data);
        });
    }

    function deleteInstance() {
        Http.delete(`/servers/${props.server.pk}/instance/`).then(res => {
            setInstance(res.data);
        });
    }

    function statsFromInstance() {
        setWaitInstance(true);
        Http.get(`/servers/${props.server.pk}/instance/stats/`).then(res => {
            props.onUpdate && props.onUpdate();
            setWaitInstance(false);
        });
    }

    function getStateColor(state) {
        switch (state) {
            case 'active':
                return 'success';
            case 'not found':
                return 'danger';
            case 'paused':
            case 'installed':
                return 'warning';
            default:
                return 'secondary';
        }
    }

    function swapOn(data) {
        if (data.size) {
            Http.post(`/servers/${props.server.pk}/scripts/`, {
                script: 'swap-set',
                args: { size: data.size*1024*1024 },
            }).then(res => {
                console.log(res.data);
            });
        }
    }

    function refreshOS() {
        Http.post(`/servers/${props.server.pk}/`, { action: 'os_info' }).then(res => {
            console.log(res.data);
        });
    }

    return (
        <div>
            <div className="row py-2">
                <div className="col-8">
                    <h5>System</h5>
                </div>
                <div className="col">
                    <div className="btn-group float-end">
                        {instance && instance.state === 'active' && <Button
                            title="Load stats from instance"
                            color="success"
                            text=<Icon icon={IconList.update}/>
                            onClick={statsFromInstance}
                            disabled={waitInstance}
                        />}
                        <RefreshButton onClick={updateData}/>
                    </div>
                </div>
                <List id="USAGE" items={[{
                    name: 'OS',
                    value: props.server.os.code,
                    button: <ConfirmButton
                        title="Update OS info"
                        text=<Icon icon={IconList.upgrade}/>
                        color="link"
                        style="btn-sm"
                        question="Do you want to refresh server OS info?"
                        onConfirm={refreshOS}
                    />,
                }, {
                    name: 'CPU',
                    value: (
                        <p>
                            {props.server.usage && props.server.usage.cpu && <Usage
                                value={`${props.server.usage.cpu}% / `}
                                check={props.server.usage.cpu}
                            />}
                            &nbsp;{props.server.os.cpu} cores
                        </p>
                    ),
                }, {
                    name: 'Memory',
                    value: props.server.usage ?
                        (<p title={`${props.server.usage.mem_percent}%`}>
                            {props.server.usage.mem && <Usage
                                value={`${formatSize(props.server.usage.mem)} / `}
                                check={props.server.usage.mem_percent}
                                min={50} max={95}
                            />}
                            {formatSize(props.server.os.mem)}
                        </p>) : <span>{formatSize(props.server.os.mem)}</span>,
                }, {
                    name: 'Swap',
                    value: props.server.usage ?
                        (<p title={`${props.server.usage.swap_percent}%`}>
                            {props.server.usage && props.server.usage.swap && <Usage
                                value={`${formatSize(props.server.usage.swap)} / `}
                                check={props.server.usage.swap_percent}
                            />}
                            {formatSize(props.server.os.swap)}
                        </p>) : <span>{formatSize(props.server.os.swap)}</span>,
                    button: <FormButton
                        title="Set up swap size"
                        text={<Icon icon={IconList.edit}/>}
                        color="link"
                        style="btn-sm"
                        inputs={[{
                            field: 'size',
                            title: 'Size',
                            type: 'number',
                            form: true,
                        }]}
                        onSubmit={swapOn}
                    />,
                }, {
                    name: 'Disk',
                    value: props.server.usage ?
                        (<p title={`${props.server.usage.disk_percent}%`}>
                            {props.server.usage && props.server.usage.disk && <Usage
                                value={`${formatSize(props.server.usage.disk)} / `}
                                check={props.server.usage.disk_percent}
                            />}
                            &nbsp;
                            {formatSize(props.server.os.disk)}
                        </p>) : <span>{formatSize(props.server.os.disk)}</span>,
                }, {
                    name: 'Last at',
                    value: <DateTime date={props.server.usage && props.server.usage.at || null} min={60*2} max={60*20}/>
                }, {
                    name: 'Instance',
                    value: <span className={'text-' + getStateColor(instance && instance.state)}>{instance && instance.state}</span>,
                }]}/>
                {instance && (
                    <div className="text-end py-2">
                        {(instance.state === 'active' || instance.state === 'installed') && <ConfirmButton
                            title="Delete instance"
                            text=<Icon icon={IconList.delete}/>
                            color="danger"
                            style="btn-sm"
                            question="Do you want to delete instance from the server?"
                            onConfirm={deleteInstance}
                        />}
                        {(instance.state === 'unknown' || instance.state === 'paused' || instance.state === 'installed') && <ConfirmButton
                            title="Check connection"
                            text=<Icon icon={IconList.update}/>
                            color="warning"
                            style="btn-sm"
                            question="Do you want to check connection with instance?"
                            onConfirm={updateInstance}
                        />}
                        {(!instance.state || instance.state === 'not found') && <ConfirmButton
                            title="Install instance to the server"
                            text=<Icon icon={IconList.upload}/>
                            color="primary"
                            style="btn-sm"
                            question="Do you want to install instance to the server?"
                            onConfirm={updateInstance}
                        />}
                    </div>
                )}
            </div>
        </div>
    )
}
