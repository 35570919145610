import { useState } from 'react';
import React from 'react';
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import Button, { FormButton, ModalButton } from '../Components/Buttons';
import ListComponent from '../Components/ListComponent';

const ServerScript = (props) => {
    const [scripts, setScripts] = useState(loadScripts);
    const [scriptSelected, setScriptSelected] = useState({});
    const [users, setUsers] = useState([]);
    const [userSelected, selectUser] = useState(null);
    const [scriptArgs, setArgs] = useState({});

    function loadScripts() {
        Http.get(`/servers/${props.server.pk}/scripts/`).then(res => {
            setScripts(res.data.results);
        });
    }

    const loadUsers = () => {
        console.log('LOG userss');
        Http.get(`/servers/${props.server.pk}/users/`).then(res => {
            setUsers(res.data.results);
        });
    }

    const selectScript = (script) => {
        let nargs = {};
        Object.keys(script.args).forEach(key => nargs[key] = script.args[key]);
        setScriptSelected(script);
        setArgs(nargs);
        loadUsers();
    }

    const setArg = (key, value) => {
        setArgs((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    const runScript = () => {
        if (scriptSelected) {
            Http.post(`/servers/${props.server.pk}/scripts/`, {
                script: scriptSelected.code,
                args: scriptArgs,
                user: userSelected,
            }).then(res => {
                console.log(res.data);
            });
        }
    }

    return <ModalButton
        title={props.title || "Run command"}
        text={props.text || <Icon icon={IconList.command}/>}
        header={props.header || "Run command"}
        color={props.color || "secondary"}
        style={props.style}
        body={(
            <div>
                <div className="mb-3">
                    <label className="form-label">Script</label>
                    <select className="form-control">
                        <option>-</option>
                        {scripts && scripts.map((script, index) => {
                            return (
                                <option key={index} value={script} onClick={() => selectScript(script)}>
                                    {script.code}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">User</label>
                    <select className="form-control">
                        <option>-</option>
                        {users && users.map((user, index) => {
                            return (
                                <option key={index} value={user.username} onClick={() => selectUser(user.username)}>
                                    {user.username}
                                </option>
                            )
                        })}
                    </select>
                </div>
                {scriptSelected && scriptSelected.args && Object.keys(scriptSelected.args).map((key, index) => {
                    return (
                        <div className="mb-3" key={index}>
                            <label className="form-label">{key}</label>
                            <input type="text" className="form-control"
                                placeholder={key}
                                value={scriptArgs && scriptArgs[key]}
                                onChange={(e) => setArg(key, e.target.value)}
                            />
                        </div>
                    )
                })}
            </div>
        )}
        footer={<Button color="primary" text="Run" style="btn-lg" onClick={runScript} disabled={!scriptSelected.code} />}
        onOpen={loadScripts}
        onClose={() => {setScripts([]);setArgs({})}}
    />;
}

export default ServerScript;
