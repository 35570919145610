import { useState } from 'react';
import ReactCountryFlag from "react-country-flag"
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import ListComponent from '../Components/ListComponent';
import Button from '../Components/Buttons';

const FIELDS = [{
    field: 'host',
    title: 'Host',
    type: 'text',
    form: true,
}, {
    field: 'country',
    title: 'Loc',
    type: 'custom',
    customRow: (country, item) => (
        <span title={`${item.country}, ${item.city}, ${item.zip}, ${item.org}, ${item.isp}\n${item.lat}, ${item.lon}`}>
            <Button
                color="light"
                style="btn-sm btn-link"
                onClick={() => {navigator.clipboard.writeText(`${item.country}, ${item.city}, ${item.zip}, ${item.org}, ${item.isp}\n${item.lat}, ${item.lon}`)}}
                text={<ReactCountryFlag countryCode={item.country}/>}
            />
            
        </span>
    ),
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}]

export default function ServerHosts({ server }) {
    function updateInfo(host) {
        Http.post(`/servers/${server.pk}/hosts/${host.pk}/update/`).then(res => {
            console.log('Updated IP info', res.data);
        });
    }

    return (
        <div>
            {server && server.pk && <ListComponent
                title="Hosts"
                url={`/servers/${server.pk}/hosts/`}
                fields={FIELDS}
                confirmButtons={[{
                    text: <Icon icon={IconList.update}/>,
                    color: 'secondary',
                    title: 'Update IP info',
                    question: 'Are you sure you want to update IP info?',
                    onConfirm: item => updateInfo(item),
                }]}
                buttons={[{
                    text: <Icon icon={IconList.copy}/>,
                    color: 'light',
                    title: 'Copy',
                    onClick: (host) => {navigator.clipboard.writeText(host.host)},
                }]}
            />}
        </div>
    );
}
