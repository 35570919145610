import React from 'react';
import ListComponent from '../Components/ListComponent';

const FIELDS = [{
    field: 'user',
    title: 'User',
    type: 'custom',
    customRow: (data) => data.name,
}, {
    field: 'command',
    title: 'Command',
    type: 'textarea',
}, {
    field: 'out',
    title: 'StdOut',
    type: 'textarea',
    textEnd: true,
}, {
    field: 'err',
    title: 'StdErr',
    type: 'textarea',
    textEnd: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}]

export default class ServerLogs extends React.Component {
    render() {
        return (
            <div>
                {this.props.server && this.props.server.pk && <ListComponent key={'server-logs-' + this.props.server.pk}
                    title="Logs"
                    url={`/servers/${this.props.server.pk}/logs/`}
                    fields={FIELDS}
                    noAdd={true} noEdit={true} noDelete={true}
                    size={this.props.size}
                />}
            </div>
        )
    }
}
