import React from 'react';
import { Icon, IconList } from '../../Components/Icon';
import FileUpload from '../../Components/FileUpload';
import Http from '../../Api/Http';
import Modal from '../../Components/Modal';
import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';
import FileListComponent from '../../Components/FileComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/servers/filestores/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
}, {
    field: 'path',
    title: 'Path',
    type: 'text',
    form: true,
}, {
    field: 'server_path',
    title: 'Server path',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'files',
    title: 'Files',
    type: 'number',
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class FileStoreListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fsFiles: null,
            files: [],
        };
        this.showFiles = this.showFiles.bind(this);
        this.initFS = this.initFS.bind(this);
        this.updateFiles = this.updateFiles.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    updateFiles(fs) {
        Http.get(`/servers/filestores/${fs.pk}/files/`).then(res => {
            this.setState({ files: res.data });
        });
    }

    showFiles(fs) {
        if (this.state.fsFiles !== fs) {
            this.setState({ fsFiles: fs });
            this.updateFiles(fs);
        }
    }

    initFS(fs) {
        Http.post(`/servers/filestores/${fs.pk}/init/`).then(res => {
            console.log(res.data);
            // TODO update items
        });
    }

    uploadFiles(fs, files) {
        Http.post(`/servers/filestores/${fs.pk}/files/`, { files }, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(res => {
            this.updateFiles(fs);
        });
    }

    deleteFile(fs, filename) {
        Http.delete(`/servers/filestores/${fs.pk}/files/${filename}`).then(res => {
            this.updateFiles(fs);
        });
    }

    render() {
        return (
            <div>
                {this.state.fsFiles && (
                    <Modal key="files"
                        title={`${this.state.fsFiles && this.state.fsFiles.name}'s files (${this.state.files.length})`}
                        onClose={() => this.setState({ fsFiles: null })} styles="modal-lg"
                        body=<FileListComponent files={this.state.files} onDelete={(file) => this.deleteFile(this.state.fsFiles, file.name)}/>
                        footer=<FileUpload onUpload={this.uploadFiles} object={this.state.fsFiles}/>
                    />
                )}
                <ListComponent key='filestores' url={URL} fields={FIELDS}
                    buttons={[{
                        text: <Icon icon={IconList.folder}/>,
                        color: 'success',
                        title: 'Files',
                        onClick: this.showFiles,
                        filter: obj => obj.files !== null,
                    }, {
                        text: <Icon icon={IconList.plus}/>,
                        color: 'warning',
                        title: 'Initialize',
                        onClick: this.initFS,
                        filter: obj => obj.files === null,
                    }]}
                />
            </div>
         );
    }
}

export class FileStoreDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}

export class FileStoreSelect extends React.Component {
    render() {
        return <SelectComponent
            url={URL}
            title='Select store'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}
