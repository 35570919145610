import { useState } from 'react';
import ListComponent from '../../Components/ListComponent';
import DetailComponent, { DetailButtonComponent } from '../../Components/DetailComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/apps/docks/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'dock',
    title: 'Dock',
    type: 'text',
    hide: true,
    form: true,
    required: true,
}, {
    field: 'filestore',
    title: 'Filestore',
    type: 'fs',
    form: true,
}, {
    field: 'repo',
    title: 'Source',
    type: 'source',
    form: true,
}, {
    field: 'files',
    title: 'Files',
    type: 'build:file',
    form: true,
    hide: true,
}, {
    field: 'ports',
    title: 'Ports',
    type: 'args',
    form: true,
}, {
    field: 'volumes',
    title: 'Volumes',
    type: 'args',
    form: true,
}, {
    field: 'environment',
    title: 'Env',
    type: 'args',
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default function DockListComponent() {
    return <ListComponent url={URL} fields={FIELDS}/>;
}

export function DockDetailComponent(props) {
    return <DetailComponent
        url={URL}
        inputs={FIELDS}
        object={props.object}
        onSubmit={props.onSubmit}
        onClose={props.onClose}
    />
}

export function DockDetailButtonComponent(props) {
    return <DetailButtonComponent
        url={URL}
        inputs={FIELDS}
        style='black btn-sm'
        pk={props.pk}
        object={props.object}
        onSubmit={props.onSubmit}
        onClose={props.onClose}
    />;
}

export function DockSelect(props) {
    return <SelectComponent
        url={URL}
        title='Select dock'
        value={props.value}
        onChange={props.onChange}
    />
}
