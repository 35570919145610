import React from 'react';
import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';

const URL = '/servers/os/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'version',
    title: 'Version',
    type: 'text',
    form: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class OSListComponent extends React.Component {
    render() {
        return <ListComponent key='os' url={URL} fields={FIELDS}/>;
    }
}

export class OSDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}
