import React from 'react';
import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/servers/services/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class ServiceListComponent extends React.Component {
    render() {
        return <ListComponent key='services' url={URL} fields={FIELDS}/>;
    }
}

export class ServiceDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}

export class ServiceSelect extends React.Component {
    render() {
        return <SelectComponent
            url={URL}
            title='Select service'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}
