import React from 'react';
import ReactCountryFlag from "react-country-flag"
import ListComponent from '../../Components/ListComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/servers/hostings/';
const FIELDS = [{
    field: 'name',
    title: 'Node',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'country',
    title: 'Country',
    type: 'custom',
    customRow: (data) => <ReactCountryFlag countryCode={data} title={data}/>,
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class HostingListComponent extends React.Component {
    render() {
        return <ListComponent key='hostings' url={URL} fields={FIELDS}/>;
    }
}

export class HostingSelect extends React.Component {
    render() {
        return <SelectComponent
            url={URL}
            title='Select hosting'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}
