import React from 'react';
import ArgsComponent from '../Servers/Components/ArgsComponent';
import CommandsComponent, { CommandSelect } from '../Servers/Components/CommandsComponent';
import { BuildFileComponent } from '../Servers/Components/BuildComponents';
import { DockSelect } from '../Servers/Components/DockComponent';
import { AppSelect } from '../Servers/Components/AppComponent';
import { FileStoreSelect } from '../Servers/Components/FileStoreComponent';
import { SourceSelect } from '../Servers/Components/SourceComponent';
import { HostingSelect } from '../Servers/Components/HostingComponent';
import { ServiceSelect } from '../Servers/Components/ServiceComponent';
import { ScriptSelect } from '../Servers/Components/ScriptComponent';

export default class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            object: {},
        };
        this.setParam = this.setParam.bind(this);
        this.setArgs = this.setArgs.bind(this);
        this.changeObject = this.changeObject.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.object && this.props.object !== this.state.object && this.setState({ object: this.props.object });
    }

    changeObject(object) {
        this.setState({ object });
        this.props.onChange && this.props.onChange(object);
    }

    setParam(key, value) {
        let object = this.state.object;
        object[key] = value;
        this.changeObject(object);
    }

    setArgs(input, args) {
        let object = this.state.object;
        object[input.field] = args;
        this.changeObject(object);
    }

    renderInput(input) {
        switch (input.type) {
            case 'number':
            case 'text':
            case 'password':
            case 'custom':
                return (
                    <input type={input.type} className="form-control" required={input.required}
                        placeholder={input.title || input.field}
                        value={this.state.object[input.field] || input.default || ''}
                        onChange={(e) => this.setParam(input.field, e.target.value)}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        className="form-control" rows={input.rows || 5} required={input.required}
                        placeholder={input.title || input.field}
                        value={this.state.object[input.field] || input.default || ''}
                        onChange={(e) => this.setParam(input.field, e.target.value)}
                    ></textarea>
                );
            case 'bool':
                return (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" required={input.required} id={input.title}
                            checked={this.state.object[input.field] || false}
                            onChange={(e) => this.setParam(input.field, e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor={input.title}>
                            {input.title || input.field}
                        </label>
                    </div>
                );
            case 'args':
                return <ArgsComponent args={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'build:file':
                return <BuildFileComponent args={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'dock':
                return <DockSelect value={this.state.object[input.field] && this.state.object[input.field].pk} onChange={(args) => this.setArgs(input, args)} />
            case 'app':
                return <AppSelect value={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'fs':
                return <FileStoreSelect value={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'source':
                return <SourceSelect value={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'hosting':
                return <HostingSelect value={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            case 'service':
                return <ServiceSelect value={this.state.object[input.field] && this.state.object[input.field].pk} onChange={(args) => this.setArgs(input, args)} />
            case 'script':
                return <ScriptSelect value={this.state.object[input.field] && this.state.object[input.field].pk} onChange={(args) => this.setArgs(input, args)} url={input.url}/>
            case 'commands':
                return <CommandsComponent commands={this.state.object[input.field] && this.state.object[input.field].split(',')} onChange={(args) => this.setArgs(input, args.join(','))} />
            case 'command':
                return <CommandSelect value={this.state.object[input.field]} onChange={(args) => this.setArgs(input, args)} />
            default:
                return <div className="alert alert-danger">Type "{input.type}" is not supported</div>;
        }
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.onSubmit && this.props.onSubmit(this.state.object);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.props.inputs && this.props.inputs.map((inp) => inp.form && (
                        <div className="mb-3" key={inp.field}>
                            {inp.type !== 'bool' && <label className="form-label">{inp.title || inp.field}</label>}
                            {this.renderInput(inp)}
                        </div>
                    ))
                }
            </form>
        );
    }
}
