import React from 'react';
import Http from '../Api/Http';
import DateTime from '../Components/DateTime';
import List from '../Components/Lists';
import Button, { ConfirmButton, FormButton } from '../Components/Buttons';

export default class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            template: {},
            blocking: false,
        };
        this.updateInfo = this.updateInfo.bind(this);
        this.initTemplate = this.initTemplate.bind(this);
        this.exportTemplate = this.exportTemplate.bind(this);
        this.importTemplate = this.importTemplate.bind(this);
    }

    updateInfo() {
        Http.get(`/apps/template/`).then(res => {
            this.setState({ template: res.data });
        });
    }

    componentDidMount() {
        this.updateInfo();
    }

    initTemplate(data) {
        data['action'] = 'init'
        this.setState({ blocking: true });
        Http.post(`/apps/template/`, data).then(res => {
            console.log(res.data);
            this.setState({ blocking: false });
            this.updateInfo();
        });
    }

    exportTemplate() {
        this.setState({ blocking: true });
        Http.post(`/apps/template/`, {'action': 'export'}).then(res => {
            console.log(res.data);
            this.setState({ blocking: false });
            this.updateInfo();
        });
    }

    importTemplate() {
        this.setState({ blocking: true });
        Http.post(`/apps/template/`, {'action': 'import'}).then(res => {
            console.log(res.data);
            this.setState({ blocking: false });
            this.updateInfo();
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-6 border rounded-2 py-2">
                    <h5>Repo</h5>
                    {this.state.template.name && <List items={[{
                        name: 'Name',
                        value: this.state.template.name,
                    }, {
                        name: 'URL',
                        value: this.state.template.repo,
                    }, {
                        name: 'Path',
                        value: this.state.template.path,
                    }]}/>}
                    {this.state.template.name ? <div className="text-center my-5">
                        <ConfirmButton
                            text="Export to git"
                            color="warning"
                            style="btn-lg"
                            question="Are you sure you want to send current template to git storage?"
                            onConfirm={this.exportTemplate}
                            disabled={this.state.blocking}
                        />
                        <ConfirmButton
                            text="Import from git"
                            color="danger"
                            style="btn-lg offset-1"
                            question="Are you sure you want to load template from git storage and rewrite all changes?"
                            onConfirm={this.importTemplate}
                            disabled={this.state.blocking}
                        />
                    </div> : <div className="text-center my-5">
                        <FormButton
                            text="Initialize"
                            color="success"
                            style="btn-lg"
                            question="Are you sure you want to load template from git storage and rewrite all changes?"
                            onSubmit={this.initTemplate}
                            disabled={this.state.blocking}
                            inputs={[{
                                field: 'url',
                                title: 'URL',
                                type: 'text',
                                form: true,
                            }]}
                        />
                    </div>}
                </div>
                <div className="col-6 border rounded-2 py-2">
                    <h5>Commits</h5>
                    <ul className="list-group">
                    {this.state.template.commits && this.state.template.commits.map((item, index) => {
                        return (
                            <li key={index} className="list-group-item">
                                <DateTime date={item.at}/> {item.text}
                            </li>
                        );
                    })}
                    </ul>
                </div>
            </div>
        );
    }
}
