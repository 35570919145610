import React from 'react';
import { Icon, IconList } from '../Components/Icon';
import NavbarComponent from '../Components/NavbarComponent';
import Template from './Template';
import AppListComponent from './Components/AppComponent';
import ConfigListComponent from './Components/ConfigComponent';
import DockListComponent from './Components/DockComponent';
import FileStoreListComponent from './Components/FileStoreComponent';
import OSListComponent from './Components/OSComponent';
import ScriptListComponent from './Components/ScriptComponent';
import ServiceListComponent from './Components/ServiceComponent';
import SourceListComponent from './Components/SourceComponent';

export default class TemplateApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.tabs = [{
            title: 'Apps',
            path: '/tmpls/apps/',
            component: <AppListComponent />,
        }, {
            title: 'Docks',
            path: '/tmpls/docks/',
            component: <DockListComponent />,
        }, {
            title: 'Configs',
            path: '/tmpls/configs/',
            component: <ConfigListComponent />,
        }, {
            title: 'Scripts',
            path: '/tmpls/scripts/',
            component: <ScriptListComponent />,
        }, {
            title: 'FileStores',
            path: '/tmpls/filestores/',
            component: <FileStoreListComponent />,
        }, {
            title: 'Sources',
            path: '/tmpls/sources/',
            component: <SourceListComponent />,
        }, {
            title: 'Services',
            path: '/tmpls/services/',
            component: <ServiceListComponent />,
        }, {
            title: 'OS',
            path: '/tmpls/os/',
            component: <OSListComponent />,
        }, {
            title: <Icon icon={IconList.config}/>,
            path: '/tmpls/template/',
            component: <Template />,
        }];
    }

    render() {
        return <NavbarComponent tabs={this.tabs} id="templates"/>;
    }
}
