import { useState } from 'react';
import { Icon, IconList } from './Icon';
import Http from '../Api/Http';
import Modal from './Modal';
import Button from './Buttons';
import FormComponent from './FormComponent';

export default function DetailComponent(props) {
    const [object, setObject] = useState(props.object || {});

    function submitForm() {
        let url = props.url;
        let method = Http.post;
        if (props.object && props.object.pk) {
            url = `${url}${props.object.pk}/`;
            method = Http.put;
        }
        method(url, object).then(res => {
            setObject({});
            props.onSubmit && props.onSubmit();
            close();
        });
    }

    function close() {
        props.onClose && props.onClose();
    }

    return (
        <Modal key={props.key}
            title={props.object ? props.object.code : 'Adding'}
            onClose={close} styles="modal-lg"
            body=<FormComponent
                object={props.object}
                inputs={props.inputs}
                onChange={(obj) => setObject(obj)}
            />
            footer={
                <div className="btn-group btn-group-lg">
                    <Button color="primary" text="Save" onClick={submitForm}/>
                    <Button color="secondary" text="Cancel" onClick={close}/>
                </div>
            }
        />
    )
}

export function DetailButtonComponent(props) {
    const [isOpen, setOpen] = useState(false);
    const [object, setObject] = useState({});

    function open() {
        if (props.object) {
            setObject(props.object);
        } else if (props.pk) {
            Http.get(`${props.url}${props.pk}/`).then(res => {
                setObject(res.data);
            });
        }
        setOpen(true);
    }

    return (
            isOpen ? <DetailComponent
                url={props.url}
                inputs={props.inputs}
                onSubmit={props.onSubmit}
                onClose={() => {setObject({});setOpen(false)}}
                object={object}
            /> :
            <Button
                color={props.color}
                style={props.style}
                onClick={open}
                title={props.title}
                text={props.text || <Icon icon={IconList.info}/>}
            />
    )
}
