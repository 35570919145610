import React from 'react';
import NavbarComponent from '../Components/NavbarComponent';
import ServerList from './ServerList';
import ServerDetail from './ServerDetail';
import HostingListComponent from './Components/HostingComponent';
import LogListComponent from './Components/LogComponent';
import SettingListComponent from './Components/SettingComponent';

export default class ServerApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.tabs = [{
            title: 'Servers',
            path: '/srvs/servers/',
            component: <ServerList />,
        }, {
            title: 'Logs',
            path: '/srvs/logs/',
            component: <LogListComponent />,
        }, {
            title: 'Settings',
            path: '/srvs/settings/',
            component: <SettingListComponent />,
        }, {
            title: 'Hostings',
            path: '/srvs/hostings/',
            component: <HostingListComponent />,
        }, {
            path: '/srvs/servers/:id/',
            component: <ServerDetail />,
        }];
    }

    render() {
        return <NavbarComponent tabs={this.tabs} id="servers"/>;
    }
}
