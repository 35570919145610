import React from 'react';
import { useState } from 'react';
import ReactCountryFlag from "react-country-flag"
import { Icon, IconList } from '../Components/Icon';
import Http from '../Api/Http';
import Modal from '../Components/Modal';
import ListComponent from '../Components/ListComponent';
import DetailComponent from '../Components/DetailComponent';
import { formatSize } from '../Components/Formats';
import DateTime from '../Components/DateTime';
import { Usage } from '../Components/Elements';
import store from '../Stores';
import CommandComponent from './Components/CommandComponent';
import ServerAppLogs from './AppLogs';

const URL = '/servers/';
const FIELDS = [{
    field: 'name', title: 'Name', type: 'custom',
    customRow: (name, obj) => <span>
        {name}
        <a className="btn" href={`/srvs/servers/${obj.pk}/`}><Icon icon={IconList.link} /></a>
    </span>,
    form: true,
    required: true,
}, {
    field: 'usage', title: 'Usage', type: 'custom',
    customRow: (data, obj) => (
        <span>
            <Usage title={"CPU x " + obj.os.cpu} value={data.cpu} min={25} max={90} round={true} percent={true}/>&nbsp;
            <Usage title={`Memory: ${formatSize(data.mem)} / ${formatSize(obj.os.mem)}`} value={data.mem_percent} min={75} max={95} round={true} percent={true}/>&nbsp;
            <Usage title={`Swap: ${formatSize(data.swap)} / ${formatSize(obj.os.swap)}`} value={data.swap_percent} min={70} max={90} round={true} percent={true}/>&nbsp;
            <Usage title={`Disk: ${formatSize(data.disk)} / ${formatSize(obj.os.disk)}`} value={data.disk_percent} min={80} max={95} round={true} percent={true}/>&nbsp;
            {data.at && <span><Icon icon={IconList.time}/> <DateTime date={data.at} min={60*2} max={60*20}/></span>}
        </span>
    ),
    noSort: true,
}, {
    field: 'hosts', title: 'Hosts', type: 'custom',
    customRow: (data) => {
        return (
            <div>
                {data.map((item, index) => {
                    return <span key={index} title={`${item.org} - ${item.zip}, ${item.city}, ${item.country}`}>{item.host} {item.country ? <ReactCountryFlag countryCode={item.country} /> : ''}</span>;
                })}
            </div>
        );
    }
}, {
    field: 'apps', title: 'Apps', type: 'text', noSort: true,
}, {
    field: 'os', title: 'OS', type: 'custom',
    customRow: data => (
        <span title={`CPU: ${data.cpu}\nMemory: ${formatSize(data.mem)}\nSwap: ${formatSize(data.swap)}\nDisk: ${formatSize(data.disk)}`}>
            {data.code}
        </span>
    ),
}, {
    field: 'hosting', title: 'Hosting', type: 'text',
}, {
    field: 'is_active', title: 'Active', type: 'bool', form: true,
}, {
    field: 'created_at', title: 'Added at', type: 'datetime',
}]
const FIELDS_APP = [{
    field: 'app', title: 'App', type: 'app', form: true,
}, {
    field: 'name', title: 'Name', type: 'text', form: true,
}, {
    field: 'user', title: 'User', type: 'text', form: true,
}]
const FIELDS_NEW_SERVER = [{
    field: 'hosting', title: 'Hosting', type: 'hosting', form: true,
}, {
    field: 'name', title: 'Name', type: 'text', form: true,
}, {
    field: 'host', title: 'Host', type: 'text', form: true,
}, {
    field: 'username', title: 'Username', type: 'text', form: true, default: 'root',
}, {
    field: 'password', title: 'Password', type: 'password', form: true,
}, {
    field: 'ssh_port', title: 'Port', type: 'number', form: true, default: 22,
}]
const FIELDS_USER = [{
    field: 'username', title: 'Username', type: 'text', form: true,
}, {
    field: 'password', title: 'Password', type: 'password', form: true,
}, {
    field: 'exists', title: 'Exists', type: 'bool', form: true,
}, {
    field: 'is_authorized', title: 'Auth-ed', type: 'bool',
}, {
    field: 'created_at', title: 'Added at', type: 'datetime',
}]

export default function ServerList(props) {
    const [apps, setApps] = useState([]);
    const [serverAdding, setServerAdding] = useState(false);
    const [waitSSI, setWaitSSI] = useState([]);
    const [serverLogs, setServerLogs] = useState(null);
    const [refreshing, setRefreshing] = useState(false);

    function handleWS() {
        const msg = store.getState();
        console.log(`MESSAGE: ${msg}`);
    }

    function updateApps(server) {
        Http.get(`/servers/${server.pk}/apps/`).then(res => {
            setApps(res.data.results);
        });
    }

    function authUser(server, user) {
        Http.post(`/servers/${server.pk}/users/${user.pk}/`).then(res => {
            console.log(res.data);
        });
    }

    function deleteUser(server, user) {
        Http.delete(`/servers/${server.pk}/users/${user.pk}/`).then(res => {
            console.log(res.data);
        });
    }

    function addServer() {
        setServerAdding(true);
    }

    function statsFromInstance(server) {
        setWaitSSI([...waitSSI, server.pk])
        Http.get(`/servers/${server.pk}/instance/stats/`).then(res => {
            setWaitSSI(waitSSI.filter((x) => x === server.pk));
            refresh();
        });
    }

    function statsAllFromInstance() {
        Http.get(`/servers/`).then(res => {
            res.data.results.map((server) => {
                statsFromInstance(server);
            });
        });
    }

    function refresh() {
        setRefreshing(!refreshing);
    }

    return (
        <div>
            {serverLogs && <Modal title='Logs' onClose={() => setServerLogs(null)} styles="modal-lg"
                body=<ServerAppLogs server={serverLogs}/>
            />}
            {serverAdding && <DetailComponent
                url={URL}
                inputs={FIELDS_NEW_SERVER}
                onSubmit={refresh}
                onClose={() => setServerAdding(false)}
            />}
            <ListComponent key='servers' url={URL} fields={FIELDS}
                noAdd={true} noEdit={true} noDelete={true}
                refresh={refreshing}
                headButtons={[{
                    icon: IconList.add,
                    color: 'info',
                    title: 'Add new server',
                    onClick: addServer,
                }, {
                    icon: IconList.update,
                    color: 'success',
                    title: 'Load stats from instances',
                    onClick: statsAllFromInstance,
                }]}
                buttons={[{
                    text: <Icon icon={IconList.update}/>,
                    color: 'success',
                    title: 'Load stats from instance',
                    filter: obj => obj.instance === 'active',
                    onClick: obj => statsFromInstance(obj),
                    disabled: obj => waitSSI && waitSSI.indexOf(obj.pk) > -1 || false,
                }, {
                    text: <Icon icon={IconList.logs}/>,
                    color: 'success',
                    title: 'Show logs',
                    filter: obj => obj.instance === 'active',
                    onClick: obj => setServerLogs(obj),
                }]}
            />
        </div>
    );
}
