export function Badge(props) {
    return (
        <span className={'badge text-bg-' + (props.style || 'secondary')}>
            {props.value}
        </span>
    )
}

export function Usage(props) {
    let style = '';
    let text = props.value;
    const value = props.check || props.value;
    if (value || value === 0) {
        const min = props.min || 20;
        const max = props.max || 90;
        if (value < min) style = 'text-success'
        else if (value > max) style = 'text-danger'
        if (props.round) text = Math.round(text);
        if (props.percent) text = `${text}%`;
    } else text = '-';
    return (
        <span className={style} title={props.title}>
            {text}
        </span>
    )
}
