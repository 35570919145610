export default function List(props) {
    return (
        <div className="list-group">
            {props.items.map((item, index) => {
                return (
                    <div key={index} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-2">
                        <div className="fw-bold">{item.name}</div>
                        {item.button}
                        <span className="text-body-secondary">{item.value}</span>
                    </div>
                )
            })}
        </div>
    )
}
