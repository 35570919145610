import React from 'react';
import { Icon, IconList } from '../../Components/Icon';
import Http from '../../Api/Http';
import Modal from '../../Components/Modal';
import Button from '../../Components/Buttons';
import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';
import FileListComponent from '../../Components/FileComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/servers/sources/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
}, {
    field: 'version',
    title: 'Version',
    type: 'text',
}, {
    field: 'url',
    title: 'Url',
    type: 'text',
    form: true,
}, {
    field: 'files',
    title: 'Files',
    type: 'number',
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class SourceListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fsFiles: null,
            files: [],
            showContent: false,
        };
        this.cloneRepo = this.cloneRepo.bind(this);
        this.pullRepo = this.pullRepo.bind(this);
        this.pushRepo = this.pushRepo.bind(this);
        this.updateFiles = this.updateFiles.bind(this);
        this.switchContent = this.switchContent.bind(this);
        this.showFiles = this.showFiles.bind(this);
    }

    cloneRepo(fs) {
        Http.post(`/servers/sources/${fs.pk}/files/`, { 'action': 'clone' }).then(res => {
            console.log(res.data);
        });
    }

    pullRepo(fs) {
        Http.post(`/servers/sources/${fs.pk}/files/`, { 'action': 'pull' }).then(res => {
            console.log(res.data);
        });
    }

    pushRepo(fs) {
        Http.post(`/servers/sources/${fs.pk}/files/`, { 'action': 'push' }).then(res => {
            console.log(res.data);
        });
    }

    updateFiles(fs) {
        let url = `/servers/sources/${fs.pk}/files/`;
        if (this.state.showContent)
            url += '?content=1'
        Http.get(url).then(res => {
            this.setState({ files: res.data });
        });
    }

    switchContent() {
        this.setState({ showContent: !this.state.showContent }, () => this.updateFiles(this.state.fsFiles))
    }

    showFiles(fs) {
        if (this.state.fsFiles !== fs) {
            this.setState({ fsFiles: fs });
            this.updateFiles(fs);
        }
    }

    render() {
        return (
            <div>
                {this.state.fsFiles && (
                    <Modal key="files" title={`${this.state.fsFiles && this.state.fsFiles.name}'s files (${this.state.files.length})`} onClose={() => this.setState({ fsFiles: null })} styles="modal-lg"
                        body=<FileListComponent files={this.state.files}/>
                        footer=<Button color="warning" text={(this.state.showContent ? 'Hide' : 'Show') + ' content'} onClick={this.switchContent}/>
                    />
                )}
                <ListComponent key='sources' url={URL} fields={FIELDS}
                    confirmButtons={[{
                        text: <Icon icon={IconList.download}/>,
                        color: 'warning',
                        title: 'Clone',
                        onConfirm: obj => this.cloneRepo(obj),
                        question: 'Are you sure you want to clone repo?',
                        filter: obj => obj.url && obj.files === null,
                    }]}
                    buttons={[{
                        text: <Icon icon={IconList.download}/>,
                        color: 'info',
                        title: 'Pull',
                        onClick: obj => this.pullRepo(obj),
                        filter: obj => obj.url && obj.files !== null,
                    }, {
                        text: <Icon icon={IconList.upload}/>,
                        color: 'secondary',
                        title: 'Push',
                        onClick: obj => this.pushRepo(obj),
                        filter: obj => obj.url && obj.files !== null,
                    }, {
                        text: <Icon icon={IconList.folder}/>,
                        color: 'success',
                        title: 'Files',
                        onClick: this.showFiles,
                        filter: obj => obj.files !== null,
                    }]}
                />
            </div>
        );
    }
}

export class SourceDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}

export class SourceSelect extends React.Component {
    render() {
        return <SelectComponent
            url={URL}
            title='Select source'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}
