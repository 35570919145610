import React from 'react';
import ListComponent from '../../Components/ListComponent';

const URL = '/servers/logs/';
const FIELDS = [{
    field: 'server',
    title: 'Server',
    type: 'custom',
    customRow: (data) => data.name,
}, {
    field: 'user',
    title: 'User',
    type: 'custom',
    customRow: (data) => data.name,
}, {
    field: 'command',
    title: 'Command',
    type: 'textarea',
}, {
    field: 'out',
    title: 'StdOut',
    type: 'textarea',
}, {
    field: 'err',
    title: 'StdErr',
    type: 'textarea',
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}]

export default class LogListComponent extends React.Component {
    render() {
        return <ListComponent
            key='logs' url={URL} fields={FIELDS}
            noAdd={true} noEdit={true} noDelete={true}
        />;
    }
}
