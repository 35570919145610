import { useState } from 'react';
import { useParams } from "react-router-dom";
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import Button, { FormButton } from '../Components/Buttons';
import ServerUsage from './Usage';
import ServerUsers from './Users';
import ServerPorts from './Ports';
import ServerHosts from './Hosts';
import ServerLogs from './Logs';
import ServerApps from './Apps';
import ServerScript from './Script';

export default function ServerDetail(props) {
    const params = useParams();
    const [server, setServer] = useState(loadServer);

    function loadServer() {
        if (params.id) {
            Http.get(`/servers/${params.id}/`).then(res => {
                setServer(res.data);
            });
        }
    }

    function deleteServer(data) {
        if (data.name != server.name) alert('Wrong server name');
        else Http.delete(`/servers/${params.id}/`).then(res => {
            props.history.push('/srvs/servers/')
        });
    }

    return (
        <div>
            {server ? (
                <div>
                    <div className="alert alert-dark text-center">
                        <h4 className="alert-heading">
                            {server && server.name}{server.hosting && ` (${server.hosting})`}
                            <span className="float-end">
                                <ServerScript server={server}/>
                            </span>
                        </h4>
                    </div>
                    <div className="row">
                        <div className="col col-12 border rounded-2 py-2">
                            <ServerApps server={server}/>
                        </div>
                        <div className="col col-4 border rounded-2">
                            {server.os && <ServerUsage server={server} onUpdate={loadServer}/>}
                        </div>
                        <div className="col col-4 border rounded-2 py-2">
                            <ServerUsers server={server}/>
                        </div>
                        <div className="col col-4 border rounded-2 py-2">
                            <div>
                                <ServerPorts server={server}/>
                            </div>
                            <div>
                                <ServerHosts server={server}/>
                            </div>
                        </div>
                        <div className="col col-12 border rounded-2 py-2">
                            <ServerLogs server={server} size={10}/>
                        </div>
                    </div>
                    <div className="py-5 float-end">
                        <Button
                            color="dark"
                            style="btn-sm"
                            text={<Icon icon={IconList.download}/>}
                        />
                        <FormButton
                            title="Delete the server"
                            color="dark"
                            style="btn-sm"
                            header="Type server name to delete it"
                            inputs={[{
                                field: 'name',
                                title: 'Name',
                                type: 'text',
                                form: true,
                            }]}
                            text={<Icon icon={IconList.delete}/>}
                            onSubmit={deleteServer}
                        />
                    </div>
                </div>
            ) : <div className="alert alert-warning">Server is not loaded yet...</div>}
        </div>
    )
}
