import React from 'react';
import Modal, { Confirm } from '../../Components/Modal';
import Button from '../../Components/Buttons';
import { Icon, IconList } from '../../Components/Icon';

export default class ArgsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            args: {},
            arg: null,
            delArg: null,
        };
        this.openAdd = this.openAdd.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.setAddParam = this.setAddParam.bind(this);
        this.add = this.add.bind(this);
        this.closeAdd = this.closeAdd.bind(this);
        this.deleteArg = this.deleteArg.bind(this);
    }

    componentDidUpdate() {
        if (typeof this.props.args === 'object' && this.props.args !== this.state.args) {
            this.setState({ args: this.props.args });
        }
    }

    openAdd() {
        this.setState({ arg: { name: '', value: '' } });
    }

    openEdit(name, value) {
        const arg = { name, value };
        this.setState({ arg });
    }

    setAddParam(name, value) {
        let arg = this.state.arg;
        arg[name] = value;
        this.setState({ arg });
    }

    add() {
        let args = this.state.args;
        args[this.state.arg.name] = this.state.arg.value;
        this.setState({ args }, () => {
            this.props.onChange && this.props.onChange(this.state.args);
            this.closeAdd();
        });
    }

    closeAdd() {
        this.setState({ arg: null });
    }

    deleteArg(name) {
        let args = this.state.args;
        delete args[name];
        this.setState({ args }, () => this.props.onChange && this.props.onChange(this.state.args));
    }

    render() {
        return (
            <div>
                {this.state.delArg &&  <Confirm text={`Are you sure you want to delete "${this.state.delArg}"?`} onConfirm={() => this.deleteArg(this.state.delArg)} onClose={() => this.setState({ delArg: null })}/>}
                {this.state.arg && <Modal title="Add arg" onClose={this.closeAdd} styles="modal-sm"
                    body={(
                        <div>
                            <div className="mb-3">
                                <label className="form-label">Name*</label>
                                <input type="text" autoFocus className="form-control" required placeholder="Name" onChange={(e) => this.setAddParam('name', e.target.value)} value={this.state.arg.name}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Value*</label>
                                <input type="text" className="form-control" placeholder="Value" onChange={(e) => this.setAddParam('value', e.target.value)} onKeyPress={(e) => e.charCode === 13 && this.add()} value={this.state.arg.value}/>
                            </div>
                        </div>
                    )}
                    footer=<Button color="primary" style="btn-lg" onClick={this.add} text="Add"/>
                />}
                <table className="table table-striped table-hover  table-responsive text-center">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>
                                <Icon icon={IconList.config}/>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {this.state.args && Object.keys(this.state.args).map((key, index) => {
                            return (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{this.state.args[key]}</td>
                                    <td>
                                        <div className="btn-group btn-group-sm">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={() => this.openEdit(key, this.state.args[key])}
                                                text={<Icon icon={IconList.edit}/>}
                                            />
                                            <Button
                                                type="button"
                                                color="danger"
                                                onClick={() => {this.setState({ delArg: key })}}
                                                text={<Icon icon={IconList.delete}/>}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan="100%">
                                <Button
                                    color="info"
                                    style="w-25"
                                    onClick={this.openAdd}
                                    title="Add argument"
                                    text={<Icon icon={IconList.add}/>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
