import { useState } from 'react';
import { Icon, IconList } from '../Components/Icon';
import Modal, { Confirm } from '../Components/Modal';
import FormComponent from './FormComponent';

export default function Button(props) {
    let styles = 'btn';
    if (props.style) styles += ' ' + props.style;
    if (props.color) styles += ' btn-outline-' + props.color;
    return (
        <button className={styles} onClick={props.onClick} title={props.title} disabled={props.disabled}>
            {props.text}
        </button>
    );
}

export const RefreshButton = (props) => {
    const [auto, setAuto] = useState(null);
    const [openAuto, setOpenAuto] = useState(false);
    const [tempAuto, setTempAuto] = useState(30);
    const [autoPid, setAutoPid] = useState(null);

    const autoPeriods = [
        { period: 2, name: '2 sec' },
        { period: 5, name: '5 sec' },
        { period: 10, name: '10 sec' },
        { period: 30, name: '30 sec' },
        { period: 60, name: '1 min' },
        { period: 120, name: '2 min' },
        { period: 300, name: '5 min' },
        { period: 600, name: '10 min' },
    ];

    const autoClick = () => {
        if (autoPid) {
            clearInterval(autoPid);
            setAuto(null);
            setAutoPid(null);
        }
        else setOpenAuto(true);
    }

    const saveAuto = () => {
        setAuto(tempAuto);
        setOpenAuto(false);
        setAutoPid(setInterval(() => props.onClick && props.onClick(), tempAuto * 1000))
    }

    return (
        <div className={'btn-group ' + props.style}>
            <Button
                color={props.color || "light"}
                onClick={props.onClick}
                disabled={props.disabled}
                title={props.title || 'Refresh data'}
                text=<Icon icon={IconList.update}/>
            />
            <Button
                color={props.color || "light"}
                style={auto && ' active'}
                onClick={autoClick}
                disabled={props.disabled}
                title="Periodical auto refresh"
                text=<Icon icon={IconList.autoupdate}/>
            />
            {openAuto && <Modal
                title="Select period of auto refreshing"
                onClose={() => setOpenAuto(false)}
                body={
                    <form>
                        <select
                            className="form-control"
                            onChange={(e) => setTempAuto(e.target.value)}
                            value={tempAuto}
                        >
                            {autoPeriods.map((item, index) => <option key={index} value={item.period}>{item.name}</option>)}
                        </select>
                    </form>
                }
                footer={
                    <Button color="primary" onClick={saveAuto} text="Activate"/>
                }
            />}
        </div>
    )
}

export const ModalButton = (props) => {
    const [isOpen, setOpen] = useState(props.default || false);

    const open = () => {
        setOpen(true);
        props.onOpen && props.onOpen();
    }

    const close = () =>  {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        isOpen ? <Modal
            title={props.header}
            onClose={close}
            body={props.bodyCalc ? props.bodyCalc() : props.body}
            footer={props.footer}
            styles={props.dialog_styles}
        /> : <Button
            color={props.color}
            style={props.style}
            onClick={open}
            title={props.title}
            text={props.text}
            disabled={props.disabled}
        />
    )
}

export const ConfirmButton = (props) => {
    const [isOpen, setOpen] = useState(props.default || false);

    const close = () =>  {
        setOpen(false);
        props.onClose && props.onClose();
    }

    return (
        isOpen ? <Confirm
            title={props.header}
            text={props.question}
            onConfirm={props.onConfirm}
            onClose={close}
            yes={props.yes}
            no={props.no}
        /> : <Button
            color={props.color}
            style={props.style}
            onClick={() => setOpen(true)}
            title={props.title}
            text={props.text}
            disabled={props.disabled}
        />
    )
}

export const FormButton = (props) => {
    const [isOpen, setOpen] = useState(props.default || false);
    const [object, setObject] = useState({});

    const open = () => {
        setOpen(true);
        props.onOpen && props.onOpen();
    }

    const close = () => {
        setOpen(false);
        props.onClose && props.onClose();
    }

    const changeObject = (object) => {
        setObject(object);
    }

    const submitForm = () => {
        props.onSubmit && props.onSubmit(object);
        setOpen(false);
    }

    return (
        isOpen ? <Confirm
            title={'FORM BTN'}
            onConfirm={submitForm}
            onClose={close}
            yes={props.yes || 'Submit'}
            no={props.no || 'Cancel'}
            text={<FormComponent inputs={props.inputs} onChange={changeObject}/>}
        /> : <Button
            color={props.color}
            style={props.style}
            onClick={open}
            title={props.title}
            text={props.text}
        />
    )
}
