import { createStore } from 'redux';

const RECV_WS = 'RECV_WS'

export function recvWS(data){
    return {
        type: RECV_WS,
        data
    }
}

export function WSReducer(state, action) {
    switch(action.type) {
        case RECV_WS:
            return action.data;
        default:
            return state;
    }
}

export default createStore(WSReducer);
