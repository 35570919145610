import React from 'react';
import Http from '../Api/Http';
import ReactCountryFlag from "react-country-flag"
import { Icon, IconList } from '../Components/Icon';
import Button, { RefreshButton } from '../Components/Buttons';

export default class TorManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manager: {},
            blocking: false,
            ip: null,
        };
        this.updateInfo = this.updateInfo.bind(this);
        this.testConn = this.testConn.bind(this);
        this.reConn = this.reConn.bind(this);
    }

    updateInfo() {
        this.setState({ blocking: true });
        Http.get(`/servers/tor-manager/`).then(res => {
            this.setState({ manager: res.data.manager || {}, blocking: false });
        });
    }

    testConn() {
        this.setState({ blocking: true });
        Http.post(`/servers/tor-manager/`, { action: 'test' }).then(res => {
            if (res.data.message) {
                this.setState({ ip: res.data.message, blocking: false });
            } else {
                console.log(res.data);
            }
        });
    }

    reConn() {
        this.setState({ blocking: true });
        Http.post(`/servers/tor-manager/`, { action: 'reconnect' }).then(res => {
            if (res.data.message) {
                this.setState({ ip: res.data.message, blocking: false });
            } else {
                console.log(res.data);
            }
        });
    }

    componentDidMount() {
        this.updateInfo();
    }

    render() {
        return (
            <div>
                <div className="text-start">
                    <div className="btn-group">
                        <Button
                            color="primary"
                            onClick={this.testConn}
                            title="Test connection"
                            text={<Icon icon={IconList.check}/>}
                            disabled={this.state.blocking}
                        />
                        <Button
                            color="warning"
                            onClick={this.reConn}
                            title="New circuit"
                            text={<Icon icon={IconList.update}/>}
                            disabled={this.state.blocking}
                        />
                    </div>
                    {this.state.ip && <span className="fw-bold mx-3">
                        {this.state.ip.ip} {this.state.ip.country && <ReactCountryFlag title={this.state.ip.country} countryCode={this.state.ip.country}/>} {this.state.ip.city}
                    </span>}
                    {this.state.blocking && <span className="fw-bold mx-3">Running...</span>}
                    <span className="float-end">
                        <RefreshButton onClick={this.updateInfo} disabled={this.state.blocking}/>
                    </span>
                </div>
                <hr/>
                <div className="text-center">
                    {this.state.manager.circuits && (
                        <ul>
                            {this.state.manager.circuits.map((value, index) => <li key={index}>{value}</li>)}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}
