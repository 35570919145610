import { useState } from 'react';
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import Button from '../Components/Buttons';
import Modal from '../Components/Modal';
import DateTime from '../Components/DateTime';
import Paginator from '../Components/Paginator';
import ListComponent from '../Components/ListComponent';
import FileListComponent from '../Components/FileComponent';
import SettingListComponent from './Components/SettingComponent';
import ServerAppLogs from './AppLogs';

const FIELDS = [{
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
}, {
    field: 'app',
    title: 'App',
    type: 'app',
    form: true,
    required: true,
}, {
    field: 'user',
    title: 'User',
    type: 'custom',
    customRow: (obj) => obj.username,
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Last at',
    type: 'datetime',
}]

export default function ServerApps(props) {
    const [buildFiles, setBuildFiles] = useState([]);
    const [commands, setCommands] = useState([]);
    const [isLog, showLog] = useState(false);

    function runBuild(app) {
        Http.post(`/servers/${props.server.pk}/apps/${app.pk}/build/`).then(res => {
            setBuildFiles(res.data.build);
        });
    }

    function openCommands(app) {
        if (app.app.commands) {
            Http.get(`/apps/commands/`).then(res => {
                setCommands(res.data.results.filter(x => x.is_default || app.app.commands.indexOf(x.name) > -1))
            });
        }
    }

    function runCommand(app, cmd) {
        const data = {
            command: cmd,
            params: {},
        }
        Http.post(`servers/${props.server.pk}/apps/${app.pk}/command/`, data).then(res => {
            console.log(res.data);
        });
    }

    return (
        <div>
            {isLog && <Modal title='Logs' onClose={() => showLog(false)} styles="modal-lg"
                body=<ServerAppLogs server={props.server}/>
            />}
            {props.server && props.server.pk && <ListComponent key={'server-apps-' + props.server.pk}
                title="Apps"
                url={`/servers/${props.server.pk}/apps/`}
                fields={FIELDS}
                noEdit={true}
                headButtons={[{
                    text: <Icon icon={IconList.logs}/>,
                    color: 'success',
                    title: 'Logs',
                    onClick: () => showLog(true),
                }]}
                modalButtons={[{
                    text: <Icon icon={IconList.build}/>,
                    color: 'success',
                    title: 'Build',
                    header: 'Build',
                    dialog_styles: 'modal-lg',
                    onOpen: runBuild,
                    bodyCalc: obj => <FileListComponent files={buildFiles}/>,
                    onClose: () => setBuildFiles([]),
                }, {
                    text: <Icon icon={IconList.command}/>,
                    color: 'info',
                    title: 'Manual commands',
                    header: 'Manual commands',
                    dialog_styles: 'modal',
                    onOpen: openCommands,
                    bodyCalc: app => (
                        <div className="btn-group-vertical">
                            {commands.map((cmd, index) => {
                                return (
                                    <Button key={index}
                                        color="warning"
                                        text={cmd.hint}
                                        onClick={() => runCommand(app, cmd.name)}
                                    />
                                )
                            })}
                        </div>
                    ),
                }, {
                    text: <Icon icon={IconList.config}/>,
                    color: 'primary',
                    title: 'Settings',
                    header: 'App Settings',
                    dialog_styles: 'modal-lg',
                    bodyCalc: app => <SettingListComponent url={`/servers/${props.server.pk}/apps/${app.pk}/settings/`}/>,
                }]}
            />}
        </div>
    )
}
