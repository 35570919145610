import { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';

export default function NavbarComponent(props) {
    const [tab, setTab] = useState(loadTab);

    function loadTab() {
        if (props.tabs) {
            for (var i=0; i < props.tabs.length; i++) {
                if (props.tabs[i].path === window.location.pathname) {
                    setTab(props.tabs[i]);
                    break;
                }
            }
        }
    }

    function selectTab(tab) {
        setTab(tab);
        localStorage.setItem(`navapp-${props.id}`, tab.path);
    }

    return (
        <div id={props.id}>
            {props.tabs && (
                <div>
                    <ul className="nav nav-underline">
                        {props.tabs.filter(tab => tab.title).map((tb) => {
                            return (
                                <li key={tb.title} className="nav-item">
                                    <Link to={tb.path} className={tab && tab === tb ? 'nav-link active' : 'nav-link'} onClick={() => selectTab(tb)}>
                                        {tb.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    <br/>
                    <Routes>
                        {props.tabs.map((tb, index) => {
                            return (
                                <Route key={index} path={tb.path} element={tb.component} />
                            )
                        })}
                    </Routes>
                </div>
            )}
        </div>
    )
}
