import React from 'react';
import Http from '../Api/Http';
import { RefreshButton } from '../Components/Buttons';
import DateTime from '../Components/DateTime';
import Paginator from '../Components/Paginator';

export default class ServerAppLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            pages: {},
            loadingLogs: false,
        };
        this.loadLogs = this.loadLogs.bind(this);
        this.changePage = this.changePage.bind(this);
        this.loadInstaLogs = this.loadInstaLogs.bind(this);
    }

    componentDidMount() {
        this.loadLogs();
    }

    loadLogs() {
        let url = '/stats/logs/';
        let args = [];
        if (this.state.pages && this.state.pages.page && this.state.pages.page > 1)
            args.push(`page=${this.state.pages.page}`);
        if (this.state.sortField)
            args.push(`ordering=${this.state.sortField}`);
        if (this.props.server)
            args.push(`server=${this.props.server.pk}`);
        if (this.props.app)
            args.push(`app=${this.props.app.pk}`);
        if (args)
            url += '?' + args.join('&');
        Http.get(url).then(res => {
            this.setState({
                logs: res.data.results,
                pages: {
                    count: res.data.count,
                    pages: res.data.pages,
                    page: res.data.page,
                },
            });
        });
    }

    loadInstaLogs() {
        this.setState({ loadingLogs: true });
        Http.get(`/servers/${this.props.server.pk}/instance/logs/`).then(res => {
            console.log(res.data)
            this.loadLogs();
            this.setState({ loadingLogs: false });
        });
    }

    changePage(page) {
        let pages = this.state.pages;
        pages.page = page;
        this.setState({ pages }, this.loadLogs());
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-8">
                        {this.state.pages.pages && <Paginator
                            pages={this.state.pages.pages}
                            page={this.state.pages.page}
                            count={this.state.pages.count}
                            onChange={this.changePage}
                        />}
                    </div>
                    <div className="col">
                        <div className="float-end">
                            <RefreshButton onClick={this.loadInstaLogs} disabled={this.state.loadingLogs} color="success"/>
                        </div>
                    </div>
                </div>
                {this.state.pages.pages && <hr/>}
                {this.state.logs && this.state.logs.map((item, index) => {
                    return (
                        <div key={index}>
                            <b><DateTime date={item.created_at}/> - {item.app && item.app.name} - {item.dock}</b> -&nbsp;{item.text}
                        </div>
                    )
                })}
            </div>
        );
    }
}
