import React from 'react';
import { Icon, IconList } from './Icon';

export default class Paginator extends React.Component {
    getPages() {
        const pagesAround = 9;
        const page = this.props.page || 1;
        return [...Array(pagesAround).keys()].reverse().map(x => page - x + Math.floor(pagesAround / 2)).filter(x => x >= 1 && x <= this.props.pages);
    }

    render() {
        return (
            <div className="row">
                {this.props.pages && this.props.pages > 1 && (
                    <div className="col-8">
                        <div className="btn-group">
                            {this.getPages().map((num) => {
                                return (
                                    <button
                                        key={`page-${num}`}
                                        className={'btn btn-outline-secondary' + (this.props.page === num ? ' active' : '')}
                                        onClick={() => this.props.onChange && this.props.onChange(num)}
                                    >
                                        {num}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                )}
                <div className="col-4">
                    <div className="btn-group float-end">
                        {this.props.page > 1 &&
                            <button className={'btn btn-outline-secondary'} onClick={() => this.props.onChange && this.props.onChange(1)}>
                                <Icon icon={IconList.arrowsLeft}/>
                            </button>
                        }
                        {this.props.page > 1 &&
                            <button className={'btn btn-outline-secondary'} onClick={() => this.props.onChange && this.props.onChange(this.props.page - 1)}>
                                <Icon icon={IconList.arrowLeft}/>
                            </button>
                        }
                        {this.props.page < this.props.pages &&
                            <button className={'btn btn-outline-secondary'} onClick={() => this.props.onChange && this.props.onChange(this.props.page + 1)}>
                                <Icon icon={IconList.arrowRight}/>
                            </button>
                        }
                        {this.props.page < this.props.pages &&
                            <button className={'btn btn-outline-secondary'} onClick={() => this.props.onChange && this.props.onChange(this.props.pages)}>
                                <Icon icon={IconList.arrowsRight}/>
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
