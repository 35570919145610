import React from 'react';
import { Icon, IconList } from './Icon';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: '' };
        this.close = this.close.bind(this);
        this.switchFullScreen = this.switchFullScreen.bind(this);
    }

    close() {
        this.props.onClose && this.props.onClose();
    }

    switchFullScreen() {
        if (this.state.fullscreen) this.setState({ fullscreen: '' });
        else this.setState({ fullscreen: ' modal-fullscreen' });
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={'modal fade show ' + this.props.styles} style={{ display: 'block' }}>
                <div className={'modal-dialog modal-dialog-scrollable modal-dialog-centered' + this.state.fullscreen}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modal-title-1">{this.props.title}</h1>
                            <div className="align-right">
                                <button className="btn btn-sm" onClick={this.switchFullScreen} title={this.state.fullscreen ? 'Normal size' : 'Fullscreen'}>
                                    <Icon icon={this.state.fullscreen ? IconList.normalscreen : IconList.fullscreen}/>
                                </button>
                                <button className="btn btn-close" onClick={this.close}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {this.props.body}
                        </div>
                        <div className="modal-footer">
                            <div className="container text-center">
                                {this.props.footer}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class Confirm extends Modal {
    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleConfirm() {
        this.props.onConfirm && this.props.onConfirm();
        this.close();
    }

    render() {
        return (
            <div className={'modal fade show ' + this.props.styles} style={{ display: 'block' }}>
                <div className={'modal-dialog modal-dialog-scrollable modal-dialog-centered' + this.state.fullscreen}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modal-title-1">{this.props.title || 'Confirm'}</h1>
                        </div>
                        <div className="modal-body">
                            {this.props.text}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger btn-lg" onClick={this.handleConfirm}>{this.props.yes || 'Yes'}</button>
                            <button className="btn btn-secondary btn-lg" onClick={this.close}>{this.props.no || 'No'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
