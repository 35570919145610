import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleCheck, faCircleXmark, faPlus, faMinus, faCheck, faFolderTree,
    faPenToSquare, faTrash, faGear, faUpload, faDownload, faInfoCircle,
    faRotate, faArrowRotateLeft, faClockRotateLeft, faClock,
    faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter,
    faSort, faSortUp, faSortDown, faDesktop, faPlay, faUser, faCopy,
    faSquareUpRight, faFloppyDisk, faTrowel, faCirclePlay,
    faAngleLeft, faAnglesLeft, faAngleRight, faAnglesRight,
    faCodePullRequest, faListUl, faSitemap, faRss
} from '@fortawesome/free-solid-svg-icons'
import { faDocker } from '@fortawesome/free-brands-svg-icons'

export function Icon(props) {
    return <FontAwesomeIcon icon={props.icon} size={props.size} title={props.title}/>
}

export const IconList = {
    yes: faCircleCheck,
    no: faCircleXmark,
    check: faCheck,

    add: faPlus,
    edit: faPenToSquare,
    delete: faTrash,
    info: faInfoCircle,
    time: faClock,
    save: faFloppyDisk,
    logs: faListUl,

    copy: faCopy,
    upload: faUpload,
    download: faDownload,
    run: faPlay,
    update: faArrowRotateLeft,
    upgrade: faRotate,
    autoupdate: faClockRotateLeft,
    command: faCirclePlay,

    plus: faPlus,
    minus: faMinus,

    config: faGear,
    fullscreen: faUpRightAndDownLeftFromCenter,
    normalscreen: faDownLeftAndUpRightToCenter,

    sort: faSort,
    sortup: faSortUp,
    sortdown: faSortDown,

    arrowLeft: faAngleLeft,
    arrowsLeft: faAnglesLeft,
    arrowRight: faAngleRight,
    arrowsRight: faAnglesRight,

    docker: faDocker,
    folder: faFolderTree,
    app: faDesktop,
    link: faSquareUpRight,
    user: faUser,
    build: faTrowel,

    pull: faCodePullRequest,
    sshManager: faSitemap,
    torManager: faRss,
}
