import React from 'react';
import { FileUploader } from "react-drag-drop-files";

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(files) {
        this.props.onUpload && this.props.onUpload(this.props.object, files);
    }

    render() {
        return (
            <FileUploader multiple={true} handleChange={this.handleChange} name="Test file" />
        );
    }
}
