import Http from '../../Api/Http';
import Modal, { Confirm } from '../../Components/Modal';
import { Icon, IconList } from '../../Components/Icon';
import ArgsComponent from './ArgsComponent';

export class BuildFileComponent extends ArgsComponent {
    constructor(props) {
        super(props);
        this.state = {
            configs: [],
            fss: [],
            sources: [],
            delArg: null,
        };
        this.types = ['config', 'filestore', 'settings'];
        this.typeParamInput = this.typeParamInput.bind(this);
    }

    componentDidMount() {
        Http.get('/servers/configs/').then(res => {
            this.setState({ configs: res.data.results });
        });
        Http.get('/servers/filestores/').then(res => {
            this.setState({ fss: res.data.results });
        });
        Http.get('/servers/sources/').then(res => {
            this.setState({ sources: res.data.results });
        });
    }

    openAdd() {
        this.setState({
            arg: {
                path: '',
                type: '',
                params: '',
            },
        });
    }

    openEdit(path, type, params) {
        const arg = { path, type, params };
        this.setState({ arg });
    }

    add() {
        let args = this.state.args;
        args[this.state.arg.path] = [this.state.arg.type, this.state.arg.params]
        this.setState({ args }, () => {
            this.props.onChange && this.props.onChange(this.state.args);
            this.closeAdd();
        });
    }

    typeParamInput() {
        if (!this.state.arg || !this.state.arg.type)
            return '<span>No data</span>';
    }

    inputByType() {
        if (this.state.arg.type === 'config')
            return (
                <select className="form-control" placeholder="Code" onChange={(e) => this.setAddParam('params', e.target.value)} value={this.state.arg.params}>
                    {this.state.configs && this.state.configs.map((item) => <option key={item.code} value={item.code}>{item.code}</option>)}
                </select>
            );
        if (this.state.arg.type === 'filestore')
            return;
/*
                <select className="form-control" placeholder="Code" onChange={(e) => this.setAddParam('params', e.target.value)} value={this.state.arg.params}>
                    {this.state.fss.map((item) => <option key={item.code} value={item.code}>{item.code}</option>)}
                </select>
            );
        if (this.state.arg.type === 'source')
            return (
                <select className="form-control" placeholder="Code" onChange={(e) => this.setAddParam('params', e.target.value)} value={this.state.arg.params}>
                    {this.state.sources.map((item) => <option key={item.code} value={item.code}>{item.code}</option>)}
                </select>
            );
*/
        return <input type="text" className="form-control" placeholder="Params" value={this.state.arg.params}
            onChange={(e) => this.setAddParam('params', e.target.value)} onKeyPress={(e) => e.charCode === 13 && this.add()}
        />;
    }

    render() {
        return (
            <div>
                {this.state.delArg &&  <Confirm text={`Are you sure you want to delete "${this.state.delArg}"?`} onConfirm={() => this.deleteArg(this.state.delArg)} onClose={() => this.setState({ delArg: null })}/>}
                {this.state.arg && <Modal title="Add file" onClose={this.closeAdd} styles="modal-sm"
                    body={(
                        <div>
                            <div className="mb-3">
                                <label className="form-label">Filename*</label>
                                <input type="text" autoFocus className="form-control" required placeholder="Filename" onChange={(e) => this.setAddParam('path', e.target.value)} value={this.state.arg.path}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Type*</label>
                                <select className="form-control" placeholder="Type" onChange={(e) => this.setAddParam('type', e.target.value)} value={this.state.arg.type}>
                                    <option value={null}>---</option>
                                    {this.types.map((key) => <option key={key} value={key}>{key}</option>)}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Params</label>
                                {this.state.arg && this.state.arg.type && this.inputByType()}
                            </div>
                        </div>
                    )}
                    footer=<button className="btn btn-outline-primary btn-lg" onClick={this.add}>Add</button>
                />}
                <table className="table table-striped table-hover table-responsive text-center">
                    <thead>
                        <tr>
                            <th>Filename</th>
                            <th>Type</th>
                            <th>Params</th>
                            <th>
                                <Icon icon={IconList.config}/>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {this.state.args && Object.keys(this.state.args).map((key, index) => {
                            return (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{this.state.args[key][0]}</td>
                                    <td>{this.state.args[key][1]}</td>
                                    <td>
                                        <div className="btn-group btn-group-sm">
                                            <button type="button" className="btn btn-outline-secondary" onClick={() => this.openEdit(key, this.state.args[key][0], this.state.args[key][1])}>
                                                <Icon icon={IconList.edit}/>
                                            </button>
                                            <button type="button" className="btn btn-outline-danger" onClick={() => {this.setState({ delArg: key })}}>
                                                <Icon icon={IconList.delete}/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan="100%">
                                <button className="btn btn-outline-info w-25" onClick={this.openAdd} title="Add argument">
                                    <Icon icon={IconList.add}/>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
