import React from 'react';
import Http from '../../Api/Http';
import Modal, { Confirm } from '../../Components/Modal';
import { Icon, IconList } from '../../Components/Icon';
import SelectComponent from '../../Components/SelectComponent';
import { ConfirmButton, FormButton } from '../../Components/Buttons';

export default class CommandsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdding: false,
            commands: [],
        };
        this.openAdd = this.openAdd.bind(this);
        this.addCmd = this.addCmd.bind(this);
        this.closeAdd = this.closeAdd.bind(this);
        this.deleteCmd = this.deleteCmd.bind(this);
    }

    componentDidUpdate() {
        if (this.props.commands !== this.state.commands) {
            this.setState({ commands: this.props.commands });
        }
    }

    openAdd() {
        this.setState({ isAdding: true });
    }

    addCmd(data) {
        let commands = this.state.commands || [];
        if (commands.indexOf(data.command) == -1) {
            commands.push(data.command);
            this.setState({ commands }, () => {
                this.props.onChange && this.props.onChange(this.state.commands);
                this.closeAdd();
            });
        }
    }

    closeAdd() {
        this.setState({ isAdding: false });
    }

    deleteCmd(name) {
        const commands = this.state.commands && this.state.commands.filter(x => x !== name);
        this.setState({ commands }, () => this.props.onChange && this.props.onChange(this.state.commands));
    }

    render() {
        return (
            <div>
                <table className="table table-striped table-hover  table-responsive text-center">
                    <thead>
                        <tr>
                            <th>Command</th>
                            <th>
                                <Icon icon={IconList.config}/>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {this.state.commands && this.state.commands.map((cmd, index) => {
                            return (
                                <tr key={index}>
                                    <td>{cmd}</td>
                                    <td>
                                        {!this.props.noDelete && <ConfirmButton
                                            title="Delete"
                                            text={<Icon icon={IconList.delete}/>}
                                            color="danger"
                                            question={`Are you sure you want to delete "${cmd}"?`}
                                            onConfirm={() => this.deleteCmd(cmd)}
                                        />}
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan="100%">
                                <FormButton
                                    title="Add command"
                                    text={<Icon icon={IconList.add}/>}
                                    color="info"
                                    style="w-25"
                                    header="Select command"
                                    inputs={[{
                                        field: 'command',
                                        title: 'Command',
                                        type: 'command',
                                        form: true,
                                        required: true,
                                    }]}
                                    onSubmit={this.addCmd}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export class CommandSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objects: [],
        };
        this.loadObjects = this.loadObjects.bind(this);
    }

    componentDidMount() {
        this.loadObjects();
    }

    loadObjects() {
        Http.get('/apps/commands/').then(res => {
            this.setState({ objects: res.data.results });
        });
    }

    render() {
        return (
            <select
                className="form-control"
                placeholder={this.props.title || 'select'}
                onChange={(e) => this.props.onChange && this.props.onChange(e.target.value)}
                value={this.props.value}
            >
                <option>-</option>
                {this.state.objects && this.state.objects.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
            </select>
        );
    }
}
