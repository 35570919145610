import React from 'react';
import ListComponent from '../../Components/ListComponent';

const URL = '/servers/settings/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'value',
    title: 'value',
    type: 'textarea',
    rows: 15,
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class SettingListComponent extends React.Component {
    render() {
        return <ListComponent key='settings' url={this.props.url || URL} fields={FIELDS}/>;
    }
}
