import React from 'react';
import ListComponent from '../../Components/ListComponent';
import DetailComponent, { DetailButtonComponent } from '../../Components/DetailComponent';

const URL = '/servers/configs/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'filename',
    title: 'Filename',
    type: 'text',
    form: true,
}, {
    field: 'text',
    title: 'Text',
    type: 'textarea',
    form: true,
    rows: 15,
    required: true,
}, {
    field: 'args',
    title: 'Args',
    type: 'args',
    form: true,
    hide: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default class ConfigListComponent extends React.Component {
    render() {
        return <ListComponent key='configs' url={URL} fields={FIELDS}/>;
    }
}

export class ConfigDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}

export class ConfigDetailButtonComponent extends React.Component {
    render() {
        return <DetailButtonComponent
            url={`${URL}bycode/`}
            inputs={FIELDS}
            style='black btn-sm'
            pk={this.props.pk}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />;
    }
}
