import React from 'react';
import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import { RefreshButton } from '../Components/Buttons';

export default class SSHManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manager: {},
            blocking: false,
        };
        this.updateInfo = this.updateInfo.bind(this);
    }

    updateInfo() {
        this.setState({ blocking: true });
        Http.get(`/servers/ssh-manager/`).then(res => {
            this.setState({ manager: res.data.manager || {} }, );
            this.setState({ blocking: false });
        });
    }

    componentDidMount() {
        this.updateInfo();
    }

    render() {
        return (
            <div>
                <RefreshButton onClick={this.updateInfo} disabled={this.state.blocking}/>
                <div className="text-center">
                    {this.state.manager.threads && this.state.manager.threads.map((value, index) => <Icon key={index} icon={value ? IconList.yes : IconList.no}/>)}
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Server</th>
                            <th>User</th>
                            <th>Processes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.manager.conns && this.state.manager.conns.map((conn, index) => {
                            return (
                                <tr key={index}>
                                    <td>{conn.server}</td>
                                    <td>{conn.user}</td>
                                    <td title={conn.proc}>{conn.proc.length}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
