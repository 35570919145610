import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/servers/scripts/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'os',
    title: 'OS',
    type: 'text',
    hide: true,
}, {
    field: 'text',
    title: 'Text',
    type: 'textarea',
    form: true,
    rows: 15,
    required: true,
}, {
    field: 'args',
    title: 'Args',
    type: 'args',
    form: true,
    hide: true,
}, {
    field: 'is_system',
    title: 'System',
    type: 'bool',
    form: true,
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}]

export default function ScriptListComponent(props) {
    return <ListComponent key='scripts' url={URL} fields={FIELDS}/>;
}

export function ScriptDetailComponent(props) {
    return <DetailComponent
        url={URL}
        inputs={FIELDS}
        object={props.object}
        onSubmit={props.onSubmit}
        onClose={props.onClose}
    />
}

export function ScriptSelect(props) {
    return <SelectComponent
        url={props.url || URL}
        title='Select dock'
        value={props.value}
        onChange={props.onChange}
    />
}
