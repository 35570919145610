import React from 'react';
import { Icon, IconList } from '../../Components/Icon';
import Http from '../../Api/Http';
import Modal, { Confirm } from '../../Components/Modal';
import ListComponent from '../../Components/ListComponent';
import DetailComponent from '../../Components/DetailComponent';
import SelectComponent from '../../Components/SelectComponent';

const URL = '/apps/apps/';
const FIELDS = [{
    field: 'code',
    title: 'Code',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'commands',
    title: 'Commands',
    type: 'commands',
    form: true,
    hide: true,
}, {
    field: 'docks',
    title: 'Docks',
    type: 'list',
}, {
    field: 'is_active',
    title: 'Active',
    type: 'bool',
    form: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}, {
    field: 'updated_at',
    title: 'Edited at',
    type: 'datetime',
}];

const FIELDS_DOCK = [{
    field: 'dock',
    title: 'Dock',
    type: 'dock',
    form: true,
    required: true,
}, {
    field: 'name',
    title: 'Name',
    type: 'text',
    form: true,
    required: true,
}, {
    field: 'depends',
    title: 'Depends on',
    type: 'text',
    form: true,
}, {
    field: 'ports',
    title: 'Ports',
    type: 'args',
    form: true,
    hide: true,
}, {
    field: 'volumes',
    title: 'Volumes',
    type: 'args',
    form: true,
    hide: true,
}, {
    field: 'environment',
    title: 'Env',
    type: 'args',
    form: true,
    hide: true,
}];

export default class AppListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dockApp: null,
            docks: [],
            editDock: null,
            addDock: false,
        };
        this.showDocks = this.showDocks.bind(this);
        this.updateDocks = this.updateDocks.bind(this);
        this.deleteDock = this.deleteDock.bind(this);
    }

    updateDocks(app) {
        Http.get(`/apps/apps/${app.pk}/docks/`).then(res => {
            this.setState({ docks: res.data.results });
        });
    }

    showDocks(app) {
        if (this.state.dockApp !== app) {
            this.setState({ dockApp: app });
            this.updateDocks(app);
        }
    }

    deleteDock(app, dock) {
        Http.delete(`/apps/apps/${app.pk}/docks/${dock.pk}`).then(res => {
            this.updateDocks(app);
        });
    }

    render() {
        return (
            <div>
                {this.state.dockApp && (
                    <Modal key="docks" styles="modal-lg"
                        title={`${this.state.dockApp && this.state.dockApp.name}'s docks (${this.state.docks.length})`}
                        onClose={() => this.setState({ dockApp: null })}
                        body={(
                            <ListComponent key={`app-${this.state.dockApp.name}-docks`}
                                url={`${URL}${this.state.dockApp.pk}/docks/`}
                                fields={FIELDS_DOCK}
                            />
                        )}
                    />
                )}
                <ListComponent key='apps' url={URL} fields={FIELDS}
                    buttons={[{
                        text: <Icon icon={IconList.docker}/>,
                        color: 'success',
                        title: 'Docks',
                        onClick: this.showDocks,
                    }]}
                />
            </div>
        );
    }
}

export class AppDetailComponent extends React.Component {
    render() {
        return <DetailComponent
            url={URL}
            inputs={FIELDS}
            object={this.props.object}
            onSubmit={this.props.onSubmit}
            onClose={this.props.onClose}
        />
    }
}

export class AppSelect extends React.Component {
    render() {
        return <SelectComponent
            url={URL}
            title='Select app'
            value={this.props.value}
            onChange={this.props.onChange}
        />
    }
}
