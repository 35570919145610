import { useState } from 'react';
import { Provider } from 'react-redux'
import store from './Stores';
import { ModalButton } from './Components/Buttons';
import { Icon, IconList } from './Components/Icon';
import NavbarComponent from './Components/NavbarComponent';
import SSHManager from './Servers/SSHManager';
import TorManager from './Servers/TorManager';
import ServerApp from './Servers/ServerApp';
import TemplateApp from './Servers/TemplateApp';

export default function App(props) {
    const [tab, setTab] = useState(loadTab);

    function selectTab(tab) {
        setTab(tab);
        localStorage.setItem('navapp-tab', tab);
    }

    function loadTab() {
        setTab(localStorage.getItem('navapp-tab') || 'servers');
    }

    return (
        <Provider store={store}>
            <div className="container">
                <div className="row">
                    <ul className="col-10 nav nav-underline">
                        <li className="nav-item">
                            <a role="button" className={tab === 'servers' ? 'nav-link active' : 'nav-link'} onClick={() => selectTab('servers')}>
                                Servers
                            </a>
                        </li>
                        <li className="nav-item">
                            <a role="button" className={tab === 'templates' ? 'nav-link active' : 'nav-link'} onClick={() => selectTab('templates')}>
                                Templates
                            </a>
                        </li>
                    </ul>
                    <div className="col text-end py-2 btn-group">
                        <ModalButton
                            text={<Icon icon={IconList.sshManager}/>}
                            title="SSH Manager"
                            header="SSH Manager"
                            color="light"
                            body={<SSHManager/>}
                        />
                        <ModalButton
                            text={<Icon icon={IconList.torManager}/>}
                            title="Tor Manager"
                            header="Tor Manager"
                            color="light"
                            body={<TorManager/>}
                            dialog_styles='modal-lg'
                        />
                    </div>
                </div>
                <hr/>
                {tab === 'servers' && <ServerApp/>}
                {tab === 'templates' && <TemplateApp/>}
            </div>
        </Provider>
    );
}
