import Http from '../Api/Http';
import { Icon, IconList } from '../Components/Icon';
import ListComponent from '../Components/ListComponent';

const FIELDS = [{
    field: 'username',
    title: 'Username',
    type: 'text',
    form: true,
}, {
    field: 'password',
    title: 'Password',
    type: 'password',
    form: true,
    noSort: true,
}, {
    field: 'exists',
    title: <Icon icon={IconList.add}/>,
    type: 'bool',
    form: true,
    noSort: true,
}, {
    field: 'is_authorized',
    title: <Icon icon={IconList.check}/>,
    type: 'bool',
    noSort: true,
}, {
    field: 'created_at',
    title: 'Added at',
    type: 'datetime',
}]


export default function ServerUsers({ server }) {
    const authUser = (user) => {
        Http.post(`/servers/${server.pk}/users/${user.pk}/`).then(res => {
            console.log('User authorized', res.data);
        });
    }

    const deleteUser = (user) => {
        Http.delete(`/servers/${server.pk}/users/${user.pk}/`).then(res => {
            console.log('User deleted', res.data);
        });
    }

    return (
        <div>
            {server && server.pk && <ListComponent
                title="Users"
                url={`/servers/${server.pk}/users/`}
                fields={FIELDS}
                noEdit={true}
                noDelete={true}
                confirmButtons={[{
                    text: <Icon icon={IconList.add}/>,
                    color: 'primary',
                    title: 'Add on server',
                    question: 'Are you sure you want to create new user on the server?',
                    filter: item => !item.exists,
                    onConfirm: item => authUser(item),
                }, {
                    text: <Icon icon={IconList.user}/>,
                    color: 'info',
                    title: 'Authorize on the server',
                    question: 'Are you sure you want to authorized the user on the server?',
                    filter: item => item.exists && !item.is_authorized,
                    onConfirm: item => authUser(item),
                }, {
                    text: <Icon icon={IconList.delete}/>,
                    color: 'danger',
                    title: 'Delete the user',
                    question: 'Are you sure you want to delete the user from the server?',
                    filter: item => item.can_delete,
                    onConfirm: item => deleteUser(server, item),
                }]}
            />}
        </div>
    );
}
