import React from 'react';
import Http from '../../Api/Http';
import WebSocketProvider from '../../Components/WebSocket';
import Modal from '../../Components//Modal';
import store from '../../Stores';

export default class CommandComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            command: '',
            stdout: [],
            stderr: [],
            unsubWS: null,
            wsChannel: null,
        };
        this.close = this.close.bind(this);
        this.handleWS = this.handleWS.bind(this);
        this.launch = this.launch.bind(this);
    }

    close() {
        this.props.onClose && this.props.onClose();
    }

    subWS() {
        this.setState({ unsubWS: store.subscribe(this.handleWS) });
    }

    handleWS(data) {
        console.log(data);
    }

    componentWillUnmount() {
        this.state.unsubWS && this.state.unsubWS();
    }

    launch() {
        console.log('launch command');
        const data = {
            'command': 'ping',
            'params': '8.8.8.8',
        }
        console.log(data);
        Http.post(`servers/${this.props.server.pk}/apps/${this.props.app.pk}/command/`, data).then(res => {
            this.setState({ wsChannel: res.data.ws});
        });
    }

    render() {
        return (
            <Modal title="Run command" onClose={this.close}
                body={(
                    <div>
                        <button className="btn btn-outline-danger" onClick={this.launch}>
                            Run ping
                        </button>
                        <hr/>
                        {this.state.wsChannel && (
                            <Modal title="Command output" styles="modal-lg"
                                body={(
                                    <WebSocketProvider channel={this.state.wsChannel}>
                                        <textarea className="form-control" rows="30">
                                            {this.state.stdout.map((line, index) => {
                                                return line;
                                            })}
                                        </textarea>
                                    </WebSocketProvider>
                                )}
                            />
                        )}
                    </div>
                )}
            />
        )
    }
}
