import React from 'react';
import Http from '../Api/Http';

export default class SelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objects: [],
        };
        this.loadObjects = this.loadObjects.bind(this);
    }

    componentDidMount() {
        this.loadObjects();
    }

    loadObjects() {
        if (this.props.url)
            Http.get(this.props.url).then(res => {
                this.setState({ objects: res.data.results });
            });
    }

    render() {
        return (
            <select
                className="form-control"
                placeholder={this.props.title || 'select'}
                onChange={(e) => this.props.onChange && this.props.onChange(e.target.value)}
                value={this.props.value}
            >
                <option>-</option>
                {this.state.objects && this.state.objects.map((item, index) => <option key={index} value={item.pk || item.name}>{item.name || item.code || item.pk}</option>)}
            </select>
        );
    }
}
